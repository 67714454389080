import React from "react";
import { GatsbyBrowser } from "gatsby";
import { ToastContainer } from "react-toastify";
import { TagManagerContextProvider } from "../core/contexts/tagManager";
import { FirebaseContextProvider } from "../core/contexts/firebase";
import { ApiProvider } from "../core/contexts/api";

const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({ element }) => {
  return (
    <>
      <TagManagerContextProvider>
        <FirebaseContextProvider>
          <ApiProvider>{element}</ApiProvider>
        </FirebaseContextProvider>
      </TagManagerContextProvider>
      <ToastContainer
        position="bottom-left"
        hideProgressBar
        className="p-4 md:p-6 lg:p-8"
      />
    </>
  );
};

export default wrapRootElement;
