import env from "env-sanitize";
import { config } from "dotenv";

const isStaging = env("STAGING", (x) => x.asBoolean(), false);
const environment = process.env.NODE_ENV;

if (isStaging) {
  config({ path: `.env.${environment}.staging` });
} else {
  config({ path: `.env.${environment}` });
}

const enableTwoFactorAuth = env(
  "GATSBY_ENABLE_TWO_FACTOR_AUTH",
  (x) => x.asBoolean(),
  false
);
const paymentRequiredStr = process.env.GATSBY_PAYMENT_REQUIRED;
const paymentRequired =
  paymentRequiredStr != null &&
  paymentRequiredStr != undefined &&
  paymentRequiredStr === "true";

const appConfig = {
  environment,
  isStaging: isStaging,
  paymentRequired: paymentRequired,
  enableTwoFactorAuth,
  recaptcha: {
    siteKey: process.env.RECAPTCHA_ENTERPRISE_SITE_KEY ?? "",
  },
  mail: {
    toEmail: process.env.MAILS_COLLECTION_TO_EMAIL ?? "info@norafinance.co.za",
  },
  algolia: {
    appId: process.env.ALGOLIA_APP_ID ?? "",
    searchKey: process.env.ALGOLIA_SEARCH_KEY ?? "",
  },
  metadata: {
    siteUrl: process.env.GATSBY_SITE_URL ?? "",
    tagManagerId: process.env.GOOGLE_TAG_MANAGER_ID ?? "",
  },
  firebaseConfig: {
    apiKey: process.env.GATSBY_FIREBASE_API_KEY,
    authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
    storageBucket: process.env.GATSBY_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.GATSBY_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.GATSBY_FIREBASE_APP_ID,
  },
};

export default appConfig;
