import { AxiosInstance } from "axios"

interface BaseOtpData {
  supportEmail: string
  supportPhoneNumber: string
}

export interface SendEmailOtpData extends BaseOtpData {
  email: string
}

export interface SendSmsOtpData extends BaseOtpData {
  phoneNumber: string
}

export interface VerifyOtpData {
  otp: string
}

export class OtpApi {
  private client: AxiosInstance

  constructor(client: AxiosInstance) {
    this.client = client
  }

  executeSendEmailOtp = (data: SendEmailOtpData) =>
    this.client.post("/mail/otp", data)

  executeSendSmsOtp = (data: SendSmsOtpData) =>
    this.client.post("/sms/otp", data)

  executeVerifyEmailOtp = (id: string, data: VerifyOtpData) =>
    this.client.post(`/mail/otp/${id}`, data)

  executeVerifySmsOtp = (id: string, data: VerifyOtpData) =>
    this.client.post(`/sms/otp/${id}`, data)
}
