import { FirebaseApp } from "firebase/app";
import {
  applyActionCode,
  Auth,
  connectAuthEmulator,
  createUserWithEmailAndPassword,
  EmailAuthProvider,
  fetchSignInMethodsForEmail,
  getAuth,
  GoogleAuthProvider,
  isSignInWithEmailLink,
  multiFactor,
  MultiFactorSession,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  RecaptchaVerifier,
  sendEmailVerification,
  sendPasswordResetEmail,
  sendSignInLinkToEmail,
  signInWithEmailAndPassword,
  signInWithEmailLink,
  signInWithPopup,
  signInWithCredential,
  updatePassword,
  updateProfile,
  AuthCredential,
  MultiFactorAssertion,
  PhoneInfoOptions,
} from "firebase/auth";
import {
  getStorage,
  FirebaseStorage,
  connectStorageEmulator,
  ref,
} from "firebase/storage";
import {
  Firestore,
  getFirestore,
  getDoc,
  doc,
  collection,
  setDoc,
  serverTimestamp,
  FirestoreDataConverter,
  DocumentData,
  CollectionReference,
  updateDoc,
  connectFirestoreEmulator,
  getDocs,
  DocumentReference,
} from "firebase/firestore";
import { UserModel } from "../models/user";
import { isBrowser } from "../utils/browser";
import config from "../utils/config";
import { LoanModel } from "../models/loan";
import { InsuranceModel } from "../models/insurance";
import { ClaimModel } from "../models/claim";
import { BusinessLoanModel } from "../models/business-loan";
import { StatusModel } from "../models/status";
import { ContactUsFormModel } from "../models/contact-us";
import { SettingModel } from "../models/setting";

export enum FirebaseAuthErrorCode {
  EmailExists = "auth/email-already-exists",
  InvalidOOBCode = "auth/invalid-oob-code",
  WrongPassword = "auth/wrong-password",
  UserNotFound = "auth/user-not-found",
  UserDisabled = "auth/user-disabled",
}

class Firebase {
  // Firebase APIs
  auth: Auth;
  db: Firestore;
  storage: FirebaseStorage;

  // Database
  usersCollection: CollectionReference<UserModel>;

  // Helper
  emailAuthProvider: EmailAuthProvider;

  // Social Sign In Method Providers
  googleProvider: GoogleAuthProvider;

  constructor(app: FirebaseApp) {
    this.auth = getAuth(app);
    this.db = getFirestore(app);
    this.storage = getStorage(app);
    this.emailAuthProvider = new EmailAuthProvider();
    this.googleProvider = new GoogleAuthProvider();
    this.usersCollection = this.createCollection<UserModel>("users");

    // Use the Firebase Emulator Local Suite for development
    // if (config.environment == "development") {
    //   connectAuthEmulator(this.auth, "http://localhost:9099");
    //   connectFirestoreEmulator(this.db, "localhost", 8080);
    //   connectStorageEmulator(this.storage, "localhost", 9199);
    // }
  }

  // --- Authentication API ---

  doCreateUserWithEmailAndPassword = async (
    firstName: string,
    lastName: string,
    email: string,
    password: string
  ) => {
    const credentials = await createUserWithEmailAndPassword(
      this.auth,
      email,
      password
    );
    await updateProfile(credentials.user, {
      displayName: `${firstName} ${lastName}`,
    });
    return credentials;
  };

  doSignInWithEmailAndPassword = async (email: string, password: string) =>
    signInWithEmailAndPassword(this.auth, email, password);

  doVerifyIsNewUser = async (email: string) => {
    const result = await fetchSignInMethodsForEmail(this.auth, email);
    // If there is zero sign in methods, then this could be a new user.
    return result;
  };

  doSendSignInLinkToEmail = async (
    email: string,
    redirectUrl: string,
    firstName?: string,
    lastName?: string
  ) => {
    const actionCodeSettings = {
      url: `${config.metadata.siteUrl}/auth/verify?type=magic_link&email=${email}&redirectUrl=${redirectUrl}&firstName=${firstName}&lastName=${lastName}`,
      handleCodeInApp: true,
    };
    await sendSignInLinkToEmail(this.auth, email, actionCodeSettings);
    // The link was successfully sent. Inform the user.
    // Save the email locally so you don't need to ask the user for it again
    // if they open the link on the same device.
    if (isBrowser()) {
      window.localStorage.setItem("emailForSignIn", email);
    }
  };

  doConfirmSignInLink = async (
    email: string,
    emailLink: string,
    firstName?: string,
    lastName?: string
  ) => {
    // Confirm the link is a sign-in with email link.
    if (isSignInWithEmailLink(this.auth, emailLink)) {
      // The client SDK will parse the code from the link for you.
      if (email) {
        const credentials = await signInWithEmailLink(
          this.auth,
          email,
          emailLink
        );
        // Clear email from storage.
        if (isBrowser()) {
          window.localStorage.removeItem("emailForSignIn");
        }

        if (firstName && lastName) {
          await updateProfile(credentials.user, {
            displayName: `${firstName} ${lastName}`,
          });
        }

        return credentials;
      }
    }
  };

  doSignInWithGoogle = async () =>
    signInWithPopup(this.auth, this.googleProvider);

  doSignOut = async () => this.auth.signOut();

  doPasswordReset = async (email: string) => {
    const actionCodeSettings = {
      url: `${config.metadata.siteUrl}/auth/verify?type=change_password&email=${email}`,
    };
    return sendPasswordResetEmail(this.auth, email, actionCodeSettings);
  };

  doSendEmailVerification = async (redirectUrl: string) => {
    const { currentUser } = this.auth;
    if (currentUser) {
      const actionCodeSettings = {
        url: `${config.metadata.siteUrl}/auth/verify?type=verified_email&email=${currentUser.email}&redirectUrl=${redirectUrl}`,
      };
      return sendEmailVerification(currentUser, actionCodeSettings);
    } else {
      throw new Error(
        "doSendEmailVerification has been invoked and the user is null"
      );
    }
  };

  doSendPhoneNumberVerification = async (
    phoneInfoOptions: string | PhoneInfoOptions,
    recaptchaVerifier: RecaptchaVerifier
  ) => {
    const phoneAuthProvider = new PhoneAuthProvider(this.auth);

    // Send SMS verification code.
    return phoneAuthProvider.verifyPhoneNumber(
      phoneInfoOptions,
      recaptchaVerifier
    );
  };

  doReauthenticateWithEmailAndPassword = async (
    email: string,
    password: string
  ) => {
    const emailPasswordCredential = EmailAuthProvider.credential(
      email,
      password
    );
    return signInWithCredential(this.auth, emailPasswordCredential);
  };

  doReauthenticateWithGoogle = async () => {
    const { currentUser } = this.auth;
    if (currentUser) {
      return await this.doSignInWithGoogle();
    } else {
      throw new Error(
        "doReauthenticateWithGoogle has been invoked and the user is null"
      );
    }
  };

  doVerifyPhoneNumberCode = async (
    verificationId: string,
    verificationCode: string
  ) => {
    const cred = PhoneAuthProvider.credential(verificationId, verificationCode);
    return PhoneMultiFactorGenerator.assertion(cred);
  };

  doEnrollUserToMFA = async (
    multiFactorAssertion: MultiFactorAssertion,
    mfaDisplayName?: string
  ) => {
    const { currentUser } = this.auth;
    if (currentUser) {
      // Complete enrollment.
      return multiFactor(currentUser).enroll(
        multiFactorAssertion,
        mfaDisplayName
      );
    } else {
      throw new Error(
        "doEnrollUserToMFA has been invoked and the user is null"
      );
    }
  };

  doGetMultiFactorSession = async () => {
    const { currentUser } = this.auth;
    if (currentUser) {
      return multiFactor(currentUser).getSession();
    } else {
      throw new Error(
        "doGetMultiFactorSession has been invoked and the user is null"
      );
    }
  };

  doGetMultiFactorEnrolledFactors = async () => {
    const { currentUser } = this.auth;
    if (currentUser) {
      return multiFactor(currentUser).enrolledFactors;
    } else {
      throw new Error(
        "doGetMultiFactorEnrolledFactors has been invoked and the user is null"
      );
    }
  };

  doVerifyActionCode = async (oobCode: string) =>
    applyActionCode(this.auth, oobCode);

  doPasswordUpdate = async (password: string) =>
    this.auth.currentUser
      ? updatePassword(this.auth.currentUser, password)
      : null;

  // --- Storage API ---
  storageRef = (path: string) => ref(this.storage, path);

  // This is just a helper to add the type to the db responses
  private createCollection = <T = DocumentData>(collectionName: string) => {
    return collection(this.db, collectionName) as CollectionReference<T>;
  };

  // --- Settings API ---
  settingsCollRef = () => collection(this.db, "settings");
  settingDocRef = (id: string) => doc(this.settingsCollRef(), id);
  settingDoc = (id: string) => getDoc(this.settingDocRef(id));
  setSettingDoc = (id: string, data: SettingModel) =>
    setDoc(this.settingDocRef(id), {
      ...data,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
    });
  updateSettingDoc = (id: string, data: SettingModel) =>
    updateDoc(this.settingDocRef(id), {
      ...data,
      updatedAt: serverTimestamp(),
    });

  // --- User API ---
  usersCollRef = () => collection(this.db, "users");
  userDocRef = (uid: string) => doc(this.usersCollRef(), uid);
  userDoc = (uid: string) => getDoc(this.userDocRef(uid));
  setUserDoc = (uid: string, data: UserModel) =>
    setDoc(this.userDocRef(uid), {
      ...data,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
    });
  updateUserDoc = (uid: string, data: UserModel) =>
    updateDoc(this.userDocRef(uid), {
      ...data,
      updatedAt: serverTimestamp(),
    });

  // --- Personal Loans Status API ---
  personalLoanStatusCollRef = (uid: string, id: string) =>
    collection(this.db, "users", uid, "personal_loans", id, "statuses");
  personalLoanStatusDocRef = (
    uid: string,
    personalLoanId: string,
    id?: string
  ) =>
    id
      ? doc(this.personalLoanStatusCollRef(uid, personalLoanId), id)
      : doc(this.personalLoanStatusCollRef(uid, personalLoanId));
  personalLoanStatusDocs = (uid: string, id: string) =>
    getDocs(this.personalLoanStatusCollRef(uid, id));
  personalLoanStatusDoc = (uid: string, personalLoanId: string, id: string) =>
    getDoc(this.personalLoanStatusDocRef(uid, personalLoanId, id));
  setPersonalLoanStatusDoc = (
    uid: string,
    id: string,
    data: StatusModel,
    reference?: DocumentReference
  ) => {
    const ref = reference ?? this.personalLoanStatusDocRef(uid, id);
    return setDoc(ref, {
      ...data,
      id: ref.id,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
    });
  };

  // --- Loans API ---
  loanCollRef = (uid: string) =>
    collection(this.db, "users", uid, "personal_loans");
  loanDocRef = (uid: string, id?: string) =>
    id ? doc(this.loanCollRef(uid), id) : doc(this.loanCollRef(uid));
  loanDocs = (uid: string) => getDocs(this.loanCollRef(uid));
  loanDoc = (uid: string, id: string) => getDoc(this.loanDocRef(uid, id));
  setLoanDoc = (
    uid: string,
    data: LoanModel,
    reference?: DocumentReference
  ) => {
    const ref = reference ?? this.loanDocRef(uid);
    return setDoc(ref, {
      ...data,
      id: ref.id,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
    });
  };
  updateLoanDoc = (uid: string, id: string, data: LoanModel) => {
    return updateDoc(this.loanDocRef(uid, id), {
      ...data,
      id, // Ensure id is never overwritten
      updatedAt: serverTimestamp(),
    });
  };

  // --- Business Loans Status API ---
  businessLoanStatusCollRef = (uid: string, id: string) =>
    collection(this.db, "users", uid, "business_loans", id, "statuses");
  businessLoanStatusDocRef = (
    uid: string,
    businessLoanId: string,
    id?: string
  ) =>
    id
      ? doc(this.businessLoanStatusCollRef(uid, businessLoanId), id)
      : doc(this.businessLoanStatusCollRef(uid, businessLoanId));
  businessLoanStatusDocs = (uid: string, id: string) =>
    getDocs(this.businessLoanStatusCollRef(uid, id));
  businessLoanStatusDoc = (uid: string, businessLoanId: string, id: string) =>
    getDoc(this.businessLoanStatusDocRef(uid, businessLoanId, id));
  setBusinessLoanStatusDoc = (
    uid: string,
    id: string,
    data: StatusModel,
    reference?: DocumentReference
  ) => {
    const ref = reference ?? this.businessLoanStatusDocRef(uid, id);
    return setDoc(ref, {
      ...data,
      id: ref.id,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
    });
  };

  // --- Business Loans API ---
  businessLoanCollRef = (uid: string) =>
    collection(this.db, "users", uid, "business_loans");
  businessLoanDocRef = (uid: string, id?: string) =>
    id
      ? doc(this.businessLoanCollRef(uid), id)
      : doc(this.businessLoanCollRef(uid));
  businessLoanDocs = (uid: string) => getDocs(this.businessLoanCollRef(uid));
  businessLoanDoc = (uid: string, id: string) =>
    getDoc(this.businessLoanDocRef(uid, id));
  setBusinessLoanDoc = (
    uid: string,
    data: BusinessLoanModel,
    reference?: DocumentReference
  ) => {
    const ref = reference ?? this.businessLoanDocRef(uid);
    return setDoc(ref, {
      ...data,
      id: ref.id,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
    });
  };
  updateBusinessLoanDoc = (
    uid: string,
    id: string,
    data: BusinessLoanModel
  ) => {
    return updateDoc(this.businessLoanDocRef(uid, id), {
      ...data,
      id, // Ensure id is never overwritten
      updatedAt: serverTimestamp(),
    });
  };

  // --- Insurances Status API ---
  insuranceStatusCollRef = (uid: string, id: string) =>
    collection(this.db, "users", uid, "insurances", id, "statuses");
  insuranceStatusDocRef = (uid: string, insuranceId: string, id?: string) =>
    id
      ? doc(this.insuranceStatusCollRef(uid, insuranceId), id)
      : doc(this.insuranceStatusCollRef(uid, insuranceId));
  insuranceStatusDocs = (uid: string, id: string) =>
    getDocs(this.insuranceStatusCollRef(uid, id));
  insuranceStatusDoc = (uid: string, insuranceId: string, id: string) =>
    getDoc(this.insuranceStatusDocRef(uid, insuranceId, id));
  setInsuranceStatusDoc = (
    uid: string,
    id: string,
    data: StatusModel,
    reference?: DocumentReference
  ) => {
    const ref = reference ?? this.insuranceStatusDocRef(uid, id);
    return setDoc(ref, {
      ...data,
      id: ref.id,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
    });
  };

  // --- Insurances API ---
  insuranceCollRef = (uid: string) =>
    collection(this.db, "users", uid, "insurances");
  insuranceDocRef = (uid: string, id?: string) =>
    id ? doc(this.insuranceCollRef(uid), id) : doc(this.insuranceCollRef(uid));
  insuranceDocs = (uid: string) => getDocs(this.insuranceCollRef(uid));
  insuranceDoc = (uid: string, id: string) =>
    getDoc(this.insuranceDocRef(uid, id));
  setInsuranceDoc = (
    uid: string,
    data: InsuranceModel,
    reference?: DocumentReference
  ) => {
    const ref = reference ?? this.insuranceDocRef(uid);
    return setDoc(ref, {
      ...data,
      id: ref.id,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
    });
  };
  updateInsuranceDoc = (uid: string, id: string, data: InsuranceModel) => {
    return updateDoc(this.insuranceDocRef(uid, id), {
      ...data,
      id, // Ensure id is never overwritten
      updatedAt: serverTimestamp(),
    });
  };

  // --- Claims Status API ---
  claimStatusCollRef = (uid: string, id: string) =>
    collection(this.db, "users", uid, "claims", id, "statuses");
  claimStatusDocRef = (uid: string, claimId: string, id?: string) =>
    id
      ? doc(this.claimStatusCollRef(uid, claimId), id)
      : doc(this.claimStatusCollRef(uid, claimId));
  claimStatusDocs = (uid: string, id: string) =>
    getDocs(this.claimStatusCollRef(uid, id));
  claimStatusDoc = (uid: string, claimId: string, id: string) =>
    getDoc(this.claimStatusDocRef(uid, claimId, id));
  setClaimStatusDoc = (
    uid: string,
    id: string,
    data: StatusModel,
    reference?: DocumentReference
  ) => {
    const ref = reference ?? this.claimStatusDocRef(uid, id);
    return setDoc(ref, {
      ...data,
      id: ref.id,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
    });
  };

  // --- Mails API ---
  mailCollRef = () => collection(this.db, "mails");
  mailDocRef = (id?: string) =>
    id ? doc(this.mailCollRef(), id) : doc(this.mailCollRef());
  setMailDoc = (data: ContactUsFormModel, reference?: DocumentReference) => {
    const ref = reference ?? this.mailDocRef();
    return setDoc(ref, {
      id: ref.id,
      to: config.mail.toEmail,
      template: {
        name: "contact_us_form",
        data,
      },
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
    });
  };

  // --- Claims API ---
  claimCollRef = (uid: string) => collection(this.db, "users", uid, "claims");
  claimDocRef = (uid: string, id?: string) =>
    id ? doc(this.claimCollRef(uid), id) : doc(this.claimCollRef(uid));
  claimDocs = (uid: string) => getDocs(this.claimCollRef(uid));
  claimDoc = (uid: string, id: string) => getDoc(this.claimDocRef(uid, id));
  setClaimDoc = (
    uid: string,
    data: ClaimModel,
    reference?: DocumentReference
  ) => {
    const ref = reference ?? this.claimDocRef(uid);
    return setDoc(ref, {
      ...data,
      id: ref.id,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
    });
  };
  updateClaimDoc = (uid: string, id: string, data: ClaimModel) => {
    return updateDoc(this.claimDocRef(uid, id), {
      ...data,
      id, // Ensure id is never overwritten
      updatedAt: serverTimestamp(),
    });
  };
}

export default Firebase;
