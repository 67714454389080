import { AxiosInstance } from "axios"

export type EmailVerificationData = {
  otp: string
}

export class SendEmailApi {
  private client: AxiosInstance

  constructor(client: AxiosInstance) {
    this.client = client
  }

  executeEmailOTPVerification = (data: EmailVerificationData) =>
    this.client.post("/email/otp-verification", data)
}
