import { AxiosInstance } from "axios"

export type VerifyReCaptchaData = {
  token: string
}

export class VerifyReCaptchaApi {
  private client: AxiosInstance

  constructor(client: AxiosInstance) {
    this.client = client
  }

  execute = (data: VerifyReCaptchaData) =>
    this.client.post("/recaptcha/verify", data)
}
